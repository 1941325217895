import { LinearProgress } from "@mui/material";

function WaitingPage() {
  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <img src="img/logo_small.webp" alt="M@ths en-vie" width="180" height="180" />
        <p className="fw-bold fs-1">M@ths en-vie</p>
        <LinearProgress />
      </div>
    </div>
  );
}

export default WaitingPage;
