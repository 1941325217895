import {
  faAppleWhole,
  faBicycle,
  faBurger,
  faCar,
  faChalkboardTeacher,
  faCloud,
  faCrown,
  faDice,
  faDog,
  faDove,
  faFishFins,
  faFrog,
  faGem,
  faGhost,
  faGift,
  faGuitar,
  faHeart,
  faHelicopter,
  faHippo,
  faHorse,
  faIgloo,
  faMotorcycle,
  faPen,
  faPlane,
  faPuzzlePiece,
  faRocket,
  faSailboat,
  faSnowman,
  faSpider,
  faTree,
  faTruck,
  faVolleyball,
} from "@fortawesome/free-solid-svg-icons";

const studentIcones = [
  faHeart,
  faMotorcycle,
  faCloud,
  faCar,
  faPen,
  faPlane,
  faBicycle,
  faTree,
  faTruck,
  faRocket,
  faCrown,
  faDice,
  faVolleyball,
  faSnowman,
  faSpider,
  faGem,
  faSailboat,
  faDog,
  faIgloo,
  faHorse,
  faHelicopter,
  faGuitar,
  faFrog,
  faFishFins,
  faDove,
  faBurger,
  faAppleWhole,
  faHippo,
  faGhost,
  faGift,
  faPuzzlePiece,
  faChalkboardTeacher,
];

export default studentIcones;
