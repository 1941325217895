import { faChildReaching } from "@fortawesome/free-solid-svg-icons";
import { ButtonLink } from "../components";

function Atelier() {
  return (
    <div className="h-100 w-100 d-flex">
      <div className="w-100 align-self-center text-center">
        <p style={{ fontSize: "calc(0.7rem + 1vh + 1vw)", marginBottom: "3vh" }}>Choisis ton niveau de classe.</p>
        <div className="m-auto w-75 d-flex flex-md-row flex-column" style={{ columnGap: "2vw", rowGap: "2vh" }}>
          <ButtonLink icone={faChildReaching} label="CP" path="GAME" params={{ nivelOrRank: "cp" }} height="10vh" fontSize={3} />
          <ButtonLink icone={faChildReaching} label="CE1" path="GAME" params={{ nivelOrRank: "ce1" }} height="10vh" fontSize={3} />
          <ButtonLink icone={faChildReaching} label="CE2" path="GAME" params={{ nivelOrRank: "ce2" }} height="10vh" fontSize={3} />
          <ButtonLink icone={faChildReaching} label="CM1" path="GAME" params={{ nivelOrRank: "cm1" }} height="10vh" fontSize={3} />
          <ButtonLink icone={faChildReaching} label="CM2" path="GAME" params={{ nivelOrRank: "cm2" }} height="10vh" fontSize={3} />
        </div>
      </div>
    </div>
  );
}

export default Atelier;
