import { useMemo } from "react";
import type { GameData } from "../types";
import { GameTypeEnum, ProblemStateEnum } from "../utils";

type ProgressBarProps = {
  game: GameData;
  isColumn?: boolean;
};

function ProgressBar({ game, isColumn = true }: Readonly<ProgressBarProps>) {
  const bar = useMemo<string[]>(() => {
    const bar = Array(Math.min(game.nbProblems, game.problems.length)).fill("lightgrey");

    let barIndex = 0;
    for (const problem of game.problems) {
      if (barIndex >= game.nbProblems) {
        break;
      }

      switch (problem.state) {
        case ProblemStateEnum.NOT_DISPLAYED:
          bar[barIndex] = "lightgrey";
          barIndex += 1;
          break;
        case ProblemStateEnum.ANSWERED_CORRECTLY:
          bar[barIndex] = game.type === GameTypeEnum.Evaluation ? "orange" : "green";
          barIndex += 1;
          break;
        case ProblemStateEnum.ANSWERED_WRONGLY:
          bar[barIndex] = game.type === GameTypeEnum.Evaluation ? "orange" : "red";
          barIndex += 1;
          break;
        default:
          break;
      }
    }

    return isColumn ? bar.toReversed() : bar;
  }, [game.problems, game.nbProblems, game.type, isColumn]);

  return (
    <div className={"h-100 w-100 d-flex flex-rox" + (isColumn ? " flex-column" : " flex-row")} style={{ transform: isColumn ? "skewY(-20deg)" : "skewX(-20deg)", borderTop: "1px solid black" }}>
      {bar.map((bgColor, i) => (
        <div key={i.toString() + bgColor} className={"border-top-0 h-100 w-100"} style={{ backgroundColor: bgColor, border: "1px solid black" }}></div>
      ))}
    </div>
  );
}

export default ProgressBar;
