import axios from "axios";
import { getGetAxiosInstance, getPostPutAxiosInstance, handleError } from "./utils";
import { GameData } from "../../../types";

class GameAPI {
  async start(nivelOrRank: string) {
    try {
      const response = await getGetAxiosInstance().get("/game/start/" + nivelOrRank);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async end(game: GameData) {
    try {
      const response = await getPostPutAxiosInstance().post("/game/end", game);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default GameAPI;
