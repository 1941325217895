import { GameData } from "../types";
import AidTypeEnum from "./AidTypeEnum";

type aidUsedProps = (game: GameData, setGame: React.Dispatch<React.SetStateAction<GameData>>, aidType: AidTypeEnum) => void;

const aidUsed: aidUsedProps = (game, setGame, aidType) => {
  setGame((old) => ({
    ...old,
    problems: [
      ...old.problems.map((problem, i) => {
        if (i === old.actualProblem) {
          return {
            ...problem,
            aidsUsed: [...problem.aidsUsed, aidType],
          };
        } else {
          return problem;
        }
      }),
    ],
    aidPointsUsed: old.aidPointsUsed + game.aidsCost[aidType],
  }));
};

const canUseAid = (game: GameData, aidType: AidTypeEnum) => {
  if (hasAlreadyUsedAid(game, aidType)) {
    return false;
  }

  if (game.aidPointsUsed > game.aidPoints - game.aidsCost[aidType]) {
    return false;
  }

  if (aidType === AidTypeEnum.SkipProblem) {
    if (game.actualProblem >= game.problems.length - 1) {
      return false;
    }
  }

  if (aidType === AidTypeEnum.AidNivel1) {
    if (game.problems[game.actualProblem].aidsUsed.includes(AidTypeEnum.AidNivel2)) {
      return false;
    }
  }

  if (aidType === AidTypeEnum.AidNivel2) {
    if (game.actualProblem < game.problems.length && ["cp", "ce1"].includes(game.problems[game.actualProblem].nivel)) {
      return false;
    }
  }

  return true;
};

const hasAlreadyUsedAid = (game: GameData, aidType: AidTypeEnum) => {
  if (game.actualProblem < game.problems.length) {
    return game.problems[game.actualProblem].aidsUsed.includes(aidType);
  }

  return false;
};

export { aidUsed, canUseAid };
