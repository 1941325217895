import axios, { AxiosError } from "axios";
import type { FormErrors } from "../../../types";
import type { Variant } from "react-bootstrap/esm/types";

type DataError = {
  message?: string;
  variant?: Variant;
  formErrors?: FormErrors;
  args?: Record<string, string>;
};

export class ApiError<TFormErrors = Record<string, string>, TArgs = undefined> extends Error {
  code: 400 | 403 | 404 | 500 | 503;
  variant: Variant;
  formErrors: FormErrors<TFormErrors>;
  args: TArgs | undefined;

  constructor(message: string, code: 400 | 403 | 404 | 500 | 503, variant: Variant, args: TArgs, formErrors: FormErrors<TFormErrors> = []) {
    super(message);
    this.name = "ApiError";
    this.code = code;
    this.variant = variant;
    this.args = args;
    this.formErrors = formErrors;
  }
}

export class ApiErrorArgs<TArgs> extends ApiError<Record<string, string>, TArgs> {}

const handleError = (error: AxiosError<DataError>): never => {
  const message = error.response?.data.message;
  const variant = error.response?.data.variant;
  const formErrors = error.response?.data.formErrors;
  const args = error.response?.data.args;
  const status = error.response?.status;

  const Error400 = "Les données saisies sont invalides.";
  const Error403 = "Vous n'avez pas les droits pour effectuer cette action, veuillez contacter un administrateur si vous pensez que cela est anormal.";
  const Error404 = "Cette ressource est introuvable.";
  const Error500 = "Une erreur est survenue avec le serveur. Veuillez contacter un administrateur ou réessayer plus tard.";
  const Error503 = "Le site est en maintenance, nous faisons au plus rapide";

  const ErrorXXX = "Veuillez vérifier votre connexion internet";

  if (status === 503) {
    throw new ApiError(message ?? Error503, status, variant ?? "danger", args);
  } else if (status === 500) {
    throw new ApiError(message ?? Error500, status, variant ?? "danger", args);
  } else if (status === 404) {
    throw new ApiError(message ?? Error404, status, variant ?? "warning", args);
  } else if (status === 403) {
    throw new ApiError(message ?? Error403, status, variant ?? "danger", args);
  } else if (status === 400) {
    throw new ApiError(message ?? Error400, status, variant ?? "warning", args, formErrors);
  } else {
    throw new ApiError(message ?? ErrorXXX, 500, "danger", args);
  }
};

const getPostPutAxiosInstance = (contentType: string = "application/json") => {
  const headers = { "Content-Type": contentType, "X-CSRF-PROTECTION": "1" };
  return createAxiosInstance(headers);
};

const getGetAxiosInstance = () => {
  return createAxiosInstance();
};

const createAxiosInstance = (headers: Record<string, string> = {}) => {
  return axios.create({
    headers: headers,
    baseURL: process.env.REACT_APP_BASE_API_URL + "/api",
    withCredentials: true,
  });
};

export { getGetAxiosInstance, getPostPutAxiosInstance, handleError };
