import React from "react";
import ReactDOM from "react-dom/client";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Link, RouterProvider, createBrowserRouter, isRouteErrorResponse, useRouteError } from "react-router-dom";

import "bootstrap/dist/js/bootstrap.min.js";
import "./styles/app-1.0.0.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Routes from "./routes";
import { MutationData } from "./types";
import { BaseLayout, ErrorPage, LayoutPage } from "./components";
import { Atelier, AtSchool, EndGame, Generic, Home, TeacherDashboard } from "./pages";
import Game from "./pages/Game";
import { ProtectedRoute } from "./components/ProtectedPagesLayout";
import { UserTypeEnum } from "./utils";
import { api, type ApiError } from "./hooks";
import { ProfilLayout, ProfilParameters } from "./pages/Profil";

declare module "@tanstack/react-query" {
  interface Register {
    defaultError: ApiError;
    mutationMeta: MutationData;
  }
}

const router = createBrowserRouter(
  [
    {
      element: <BaseLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          element: <LayoutPage scrollable={false} />,
          children: [
            {
              path: Routes.BASE,
              element: <Generic />,
            },
            {
              path: Routes.HOME,
              element: <Home />,
            },
            {
              path: Routes.ATELIER,
              element: <Atelier />,
            },
            {
              path: Routes.GAME,
              element: <Game />,
              errorElement: <ErrorPage />,
              loader: async ({ params }) => {
                const { nivelOrRank } = params as { nivelOrRank: string };

                return api.game.start(nivelOrRank);
              },
            },
            {
              path: Routes.END_GAME,
              element: <EndGame />,
            },
            {
              path: Routes.AT_SCHOOL,
              element: (
                <ProtectedRoute userType={UserTypeEnum.Teacher}>
                  <AtSchool />
                </ProtectedRoute>
              ),
            },
            {
              path: Routes.AT_HOME,
              element: (
                <ProtectedRoute userType={UserTypeEnum.Parent}>
                  <p className="text-center fs-3" style={{ marginTop: "100px" }}>
                    Le module pour les parents n'a pas encore été fait
                  </p>
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          element: <LayoutPage scrollable />,
          children: [
            {
              path: Routes.TEACHER_DASHBOARD,
              element: (
                <ProtectedRoute userType={UserTypeEnum.Teacher} needSudoMode>
                  <TeacherDashboard />
                </ProtectedRoute>
              ),
            },
            {
              path: Routes.PROFIL,
              element: (
                <ProtectedRoute userType={null}>
                  <ProfilLayout />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: Routes.PROFIL_PARAMETERS,
                  element: <ProfilParameters />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  { basename: process.env.REACT_APP_BASENAME_ROUTER }
);

function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <div className="mx-auto text-center" style={{ maxWidth: 960, marginTop: "50vh", transform: "translateY(-50%)" }}>
          <img src="img/logo_small.webp" alt="M@ths en-vie" width="180" height="180" />
          <h1>Erreur 404 :(</h1>
          <p className="mt-3 lead fw-bold">La page que vous cherchez n'existe plus ou n'as jamais existé.</p>
          <Link className="btn btn-ternary mt-3" to={Routes.HOME}>
            Retourner à la page d'acceuil
          </Link>
        </div>
      );
    }
  }

  return (
    <div className="mx-auto text-center" style={{ maxWidth: 960, marginTop: "50vh", transform: "translateY(-50%)" }}>
      <img src="img/logo_small.webp" alt="M@ths en-vie" width="180" height="180" />
      <h1>Erreur :(</h1>
      <p className="mt-3 lead fw-bold">Une erreur est survenue.</p>
      <Link className="btn btn-ternary mt-3" to={Routes.HOME}>
        Retourner à la page d'acceuil
      </Link>
    </div>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      networkMode: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "always" : "online",
    },
    mutations: {
      networkMode: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "always" : "online",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);
