import { Outlet, useNavigation } from "react-router-dom";
import Header from "./Header";
import { ToastContainer } from "react-toastify";
import WaitingPage from "./WaitingPage";

type LayoutPageProps = {
  scrollable: boolean;
};

const headerHeight = 50;

const LayoutPage = ({ scrollable }: LayoutPageProps) => {
  const { state: navigationState } = useNavigation();

  if (scrollable) {
    return (
      <>
        <Header height={headerHeight} />
        <div className="container-fluid" style={{ marginTop: headerHeight }}>
          <div style={{ padding: "20px 0px" }}>
            <Outlet />
          </div>
        </div>
        <ToastContainer autoClose={5000} position="bottom-right" hideProgressBar draggable={false} limit={2} />
        {/* <Footer /> */}
      </>
    );
  } else {
    return (
      <div
        style={{
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: "flex",
          position: "absolute",
          flexDirection: "column",
          justifyContent: "space-between",
          contain: "layout size style",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <Header height={headerHeight} />
        <div className="overflow-hidden" style={{ height: "calc(100vh - " + headerHeight + "px)", marginTop: headerHeight }}>
          {navigationState === "loading" && <WaitingPage />}
          <Outlet />
        </div>
        <ToastContainer autoClose={5000} position="bottom-right" hideProgressBar draggable={false} limit={2} />
        {/* <Footer /> */}
      </div>
    );
  }
};

export default LayoutPage;
