import { Alert, CloseButton, Modal } from "react-bootstrap";
import { HelpRequest, ModalPropsDefault, Student } from "../../types";
import { api, ApiError, useUtils } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { StudentsKey } from "../../pages/query";
import TextProblemTypeEnum, { getOperators } from "../../utils/TextProblemTypeEnum";
import { useEffect, useState } from "react";

type HelpRequestsModalProps = {
  _student: Student;
};

function HelpRequestsModal({ show, closeModal, _student }: ModalPropsDefault & HelpRequestsModalProps) {
  const { addBoldToQuestion, br, sanitize } = useUtils();
  const queryClient = useQueryClient();

  const [student, setStudent] = useState<Student>(_student);

  const treatHelpRequest = useMutation<{ id: number }, ApiError, number>({
    mutationFn: (id: number) => {
      return api.helpRequest.treat(id);
    },
    onSuccess: (data) => {
      queryClient.setQueryData<Student[]>(StudentsKey, (old) =>
        old
          ? old.map((oldStudent) => {
              if (oldStudent.id !== student.id) {
                return oldStudent;
              } else {
                return {
                  ...oldStudent,
                  helpRequests: oldStudent.helpRequests.filter((helpRequest) => helpRequest.id !== data.id),
                };
              }
            })
          : old
      );

      setStudent((old) => ({ ...old, helpRequests: old.helpRequests.filter((helpRequest) => helpRequest.id !== data.id) }));
    },
  });

  useEffect(() => {
    if (student.helpRequests.length === 0) {
      closeModal();
    }
  }, [student, closeModal]);

  return (
    <Modal show={show} onHide={closeModal} size="lg">
      <Modal.Header>
        <h4>Demandes d'aide</h4>
        <CloseButton variant="white" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {treatHelpRequest.isError && <Alert variant={treatHelpRequest.error.variant}>{treatHelpRequest.error.message}</Alert>}
        {student.helpRequests.map((helpRequest: HelpRequest, index) => (
          <div key={helpRequest.id} className="d-flex">
            <div>
              {index !== 0 && <hr />}
              <p>
                Problème : <span dangerouslySetInnerHTML={sanitize(addBoldToQuestion(br(helpRequest.problem.statement)))}></span>
              </p>
              <span className="badge text-bg-secondary text-black">{TextProblemTypeEnum[helpRequest.problem.type]}</span>
              {getOperators(TextProblemTypeEnum[helpRequest.problem.type]) && (
                <span className="badge text-bg-secondary text-black ms-1">{getOperators(TextProblemTypeEnum[helpRequest.problem.type])}</span>
              )}
              <p className="mt-3">
                Réponse de l'élève : <span className="fw-bold">{helpRequest.responseGiven}</span>
              </p>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-danger btn-sm align-self-center"
                onClick={() => {
                  treatHelpRequest.mutate(helpRequest.id);
                }}
                disabled={treatHelpRequest.isPending}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
}

export default HelpRequestsModal;
