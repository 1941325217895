/**
 * All available routes for the app
 */

const Routes = {
  BASE: "/",
  HOME: "/home",
  AT_SCHOOL: "/en-classe",
  AT_HOME: "/a-la-maison",
  TEACHER_DASHBOARD: "/tableau-de-bord-enseignant",
  GAME: "/game/:nivelOrRank",
  ATELIER: "/atelier",
  END_GAME: "/resutats",

  PROFIL: "/profil",
  PROFIL_PARAMETERS: "/profil/parameters",

  // Others url
  APPS: process.env.REACT_APP_APPS_URL as string,

  APPS_LOGIN: process.env.REACT_APP_APPS_URL + "/login",
  APPS_REGISTER: process.env.REACT_APP_APPS_URL + "/register",

  APPS_PROFIL_PARAMETERS: process.env.REACT_APP_APPS_URL + "/profil/parameters",

  APPS_LEGAL_NOTICES: process.env.REACT_APP_APPS_URL + "/mentions-legales",
  APPS_PRIVACY: process.env.REACT_APP_APPS_URL + "/privacy",
  APPS_CGU: process.env.REACT_APP_APPS_URL + "/cgu",
  APPS_CONTACT: process.env.REACT_APP_APPS_URL + "/contact",
  APPS_CREDITS: process.env.REACT_APP_APPS_URL + "/credits",
} as const;

export default Routes;
