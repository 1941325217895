import { faPersonDigging, faSchool, faTowerObservation } from "@fortawesome/free-solid-svg-icons";
import { ButtonLink } from "../components";
import { useAuth } from "../contexts/auth";
import { UserTypeEnum } from "../utils";
import Routes from "../routes";
import { ButtonHref } from "../components/Buttons";

function Home() {
  const { user } = useAuth();

  const loginURL = new URL(Routes.APPS_LOGIN);
  loginURL.searchParams.append("redirect", window.location.href);

  return (
    <div className="d-flex flex-column justify-content-around h-100 m-auto" style={{ width: "70%" }}>
      <ButtonLink icone={faTowerObservation} path="ATELIER" label="L'atelier" fontSize={7} height="15vh" />
      {user ? (
        <ButtonLink icone={faSchool} path="AT_SCHOOL" label="En classe" fontSize={7} height="15vh" disabled={user && user.userType === UserTypeEnum.Parent} />
      ) : (
        <ButtonHref path={loginURL.toString()} icone={faSchool} label="En classe" fontSize={7} height="15vh" />
      )}
      <ButtonLink icone={faPersonDigging} iconeBgColor="red" path="AT_HOME" label="À la maison" fontSize={7} height="15vh" disabled={!user || user.userType === UserTypeEnum.Teacher} />
    </div>
  );
}

export default Home;
