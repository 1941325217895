import { faHeadphones, faPause } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

type SpeechButtonProps = {
  text: string;
  bgColor?: "primary" | "secondary";
};

function SpeechButton({ text, bgColor = "secondary" }: Readonly<SpeechButtonProps>) {
  const [isPlaying, setIsPlaying] = useState(false);

  const startSpeak = () => {
    if (window.speechSynthesis.speaking) {
      return;
    }

    const msg = new SpeechSynthesisUtterance(text);
    msg.lang = "fr-FR";
    msg.voice = window.speechSynthesis.getVoices().filter((voice) => /fr[-_]FR/.test(voice.lang))[0];

    msg.onstart = () => setIsPlaying(true);
    msg.onend = () => setIsPlaying(false);

    window.speechSynthesis.speak(msg);

    let r = setInterval(() => {
      if (!window.speechSynthesis.speaking) {
        clearInterval(r);
      } else {
        window.speechSynthesis.pause();
        window.speechSynthesis.resume();
      }
    }, 14000);
  };

  const cancelSpeak = () => {
    setIsPlaying(false);
    window.speechSynthesis.cancel();
  };

  useEffect(() => {
    cancelSpeak();
  }, [text]);

  return (
    <button
      className={`btn btn-${bgColor} position-absolute rounded-end-0 rounded-start-5`}
      style={{ right: "0", top: "calc(50% - 30px)", width: 30, height: 60 }}
      onClick={() => {
        if (isPlaying) {
          cancelSpeak();
        } else {
          startSpeak();
        }
      }}
    >
      <FontAwesomeIcon className="position-absolute" style={{ right: 4, top: "calc(50% - 8px)" }} icon={isPlaying ? faPause : faHeadphones} />
    </button>
  );
}

export default SpeechButton;
