import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalTypeEnum, studentIcones, UserTypeEnum } from "../utils";
import { api } from "../hooks";
import { StudentsKey } from "./query";
import { useQuery } from "@tanstack/react-query";
import { Student } from "../types";
import { generatePath, useNavigate } from "react-router-dom";
import Routes from "../routes";
import { LinearProgress } from "@mui/material";
import { useEffect } from "react";
import { useModals } from "../contexts/modals";
import { useAuth } from "../contexts/auth";
import { userInfo } from "os";

function AtSchool() {
  const navigate = useNavigate();
  const { openModal } = useModals();
  const { user } = useAuth();

  const {
    data: students,
    isSuccess,
    isLoading,
  } = useQuery<Student[]>({
    queryKey: StudentsKey,
    queryFn: api.student.getStudents,
    staleTime: 60 * 1000, // 1 min
  });

  useEffect(() => {
    if (isSuccess && students.filter((student) => student.isEnabled).length === 0) {
      openModal(ModalTypeEnum.Message, {
        title: "Configurez votre classe",
        content: "Aucun élève n'a été configuré. Rendez-vous dans le tableau de bord depuis votre profil afin de renseigner le niveau de vos élèves.",
        buttons: [{ label: "Accéder au tableau de bord", onButtonClick: () => openModal(ModalTypeEnum.SudoMode, { userType: UserTypeEnum.Teacher }) }],
      });
    }
  }, [students, isSuccess, openModal]);

  useEffect(() => {
    if (user && user.schoolZone === null) {
      openModal(ModalTypeEnum.Message2, {
        title: "Configurez votre zone scolaire",
        content: "Rendez-vous dans les paramètres pour configurer votre zone scolaire.",
        buttons: [{ label: "Accéder aux paramètres", onButtonClick: () => navigate(Routes.PROFIL_PARAMETERS) }],
      });
    }
  }, [user, openModal, navigate]);

  return (
    <div className="h-100 mx-auto container">
      <div className="row h-100" style={{ padding: "1vh 0" }}>
        {isLoading && <LinearProgress className="m-auto" style={{ maxWidth: 960 }} />}
        {isSuccess &&
          students.map((student) => (
            <div key={student.rank} className="d-flex" style={{ height: "25%", width: "12.5%" }}>
              <button
                className="btn btn-primary w-100 align-self-center"
                style={{ filter: "drop-shadow(0 0 0.15rem black)", padding: "calc(2vh)", height: "90%", borderRadius: 20 }}
                onClick={() => navigate(generatePath(Routes.GAME, { nivelOrRank: student.rank.toString() }))}
                disabled={!student.isEnabled}
              >
                <FontAwesomeIcon className={`${student.rank === 31 ? "text-danger" : "text-secondary"} h-100 w-100`} style={{ height: "100%", maxWidth: "100%" }} icon={studentIcones[student.rank]} />
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}

export default AtSchool;
