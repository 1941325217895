import axios from "axios";
import { getGetAxiosInstance, getPostPutAxiosInstance, handleError } from "./utils";

class StudentAPI {
  async getStudent(studentId: number) {
    try {
      const response = await getGetAxiosInstance().get("/student/" + studentId);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async getStudents() {
    try {
      const response = await getGetAxiosInstance().get("/students");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async addStudents(data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/student/add", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default StudentAPI;
