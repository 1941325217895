import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import { LinearProgress } from "@mui/material";
import Routes from "../../routes";
import { UserTypeEnum } from "../../utils";

type ProtectedRouteProps = {
  needAdmin?: boolean;
  needSudoMode?: boolean;
  userType: UserTypeEnum | null;
};

export default function ProtectedRoute({ children, userType = null, needSudoMode = false, needAdmin = false }: React.PropsWithChildren<ProtectedRouteProps>) {
  const { user, isInitialLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isInitialLoading && (!user || (needAdmin && !user.isAdmin) || (userType !== null && user?.userType !== userType))) {
      const loginURL = new URL(Routes.APPS_LOGIN);
      loginURL.searchParams.append("redirect", window.location.origin + (process.env.REACT_APP_BASENAME_ROUTER === "/" ? "" : process.env.REACT_APP_BASENAME_ROUTER) + Routes.HOME);
      window.location.replace(loginURL);
    }

    if (!isInitialLoading && user && needSudoMode && !user.inSudoMode) {
      navigate(user.userType === UserTypeEnum.Teacher ? Routes.AT_SCHOOL : Routes.AT_HOME);
    }
  }, [user, isInitialLoading, navigate, needAdmin, userType, needSudoMode]);

  let canConnect: boolean = !!user;
  if (needAdmin) canConnect = user?.isAdmin ?? false;
  if (userType) {
    canConnect = user?.userType === userType;

    if (needSudoMode && !user?.inSudoMode) {
      canConnect = false;
    }
  }

  return canConnect ? children : <LinearProgress className="m-auto" style={{ maxWidth: 960 }} />;
}
