import { Alert } from "react-bootstrap";
import { useRouteError } from "react-router-dom";
import { ApiErrorArgs } from "../hooks";
import { GameTypeEnum } from "../utils";
import { ButtonLink } from "./Buttons";
import { faCheck, faCheckCircle, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ErrorPageProps = {
  error?: ApiErrorArgs<{ gameType?: GameTypeEnum }>;
};

function ErrorPage({ error }: Readonly<ErrorPageProps>) {
  const _error = (useRouteError() as ApiErrorArgs<{ gameType?: GameTypeEnum }>) ?? error;

  function getNavigateRoute() {
    if (_error?.args?.gameType) {
      if (_error.args.gameType === GameTypeEnum.Demo) {
        return "ATELIER";
      } else {
        return "AT_SCHOOL";
      }
    } else {
      return "HOME";
    }
  }

  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center flex-column">
      <Alert className="fs-3" variant={_error.variant}>
        {_error.variant === "success" && <FontAwesomeIcon className="text-success me-3" size="xl" icon={faCheckCircle} />}
        {_error.message}
      </Alert>
      <div className="w-25" style={{ marginTop: 140 }}>
        <ButtonLink icone={faHome} path={getNavigateRoute()} label="Retour à l'accueil" fontSize={2.4} height="10vh" />
      </div>
    </div>
  );
}

export default ErrorPage;
