import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../contexts/auth";
import { ApiError, api } from "../../hooks";
import { useForm } from "react-hook-form";
import { FormButtonLoading } from "..";
import { Alert, CloseButton, Modal } from "react-bootstrap";
import { DefaultErrorsType, ModalPropsDefault, MutationData } from "../../types";
import { UserTypeEnum } from "../../utils";
import { useNavigate } from "react-router-dom";
import Routes from "../../routes";

type FormValues = {
  password: string;
};

const defaultValues: FormValues = {
  password: "",
};

const defaultErrors: DefaultErrorsType<FormValues> = {
  password: {
    required: "Veuillez saisir votre mot de passe.",
  },
};

type SudoModeModalProps = {
  userType: UserTypeEnum;
};

function SudoModeModal({ show, closeModal, userType }: ModalPropsDefault & SudoModeModalProps) {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const { setInSudoMode } = useAuth();
  const navigate = useNavigate();

  const sudoModeMutation = useMutation<MutationData & { inSudoMode?: boolean }, ApiError<FormValues>, FormValues>({
    mutationFn: (sudoModeData) => {
      return api.user.sudoMode(sudoModeData);
    },
    onSuccess: (data) => {
      reset();
      if (data.inSudoMode !== undefined) {
        setInSudoMode(data.inSudoMode);
      }

      navigate(Routes.TEACHER_DASHBOARD);
      closeModal();
    },
    onError: (data) => {
      data.formErrors.forEach(({ name, message }) => {
        setError(name, { message: message });
      });
    },
  });
  const onSubmit = (data: FormValues) => {
    sudoModeMutation.mutate(data);
  };

  return (
    <Modal show={show} onHide={closeModal} size="lg">
      <Modal.Header>
        <h4>Accédez à l'espace {userType ? "enseignant" : "parent"}</h4>
        <CloseButton variant="white" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {sudoModeMutation.isError && sudoModeMutation.error.formErrors.length === 0 && <Alert variant={sudoModeMutation.error.variant}>{sudoModeMutation.error.message}</Alert>}
          {sudoModeMutation.isSuccess && <Alert variant="success">{sudoModeMutation.data.message}</Alert>}
          {errors.root && <Alert variant="danger">{errors.root.message}</Alert>}

          <p className="mb-3">Veuillez renseigner votre mot de passe</p>

          <div className="mb-3 input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faLock} />
            </span>
            <div className="form-floating">
              <input
                type="password"
                {...register("password", defaultErrors.password)}
                id="current-password"
                className={"form-control" + (errors.password ? " is-invalid" : "")}
                placeholder="Email"
                required
                autoFocus
              />
              <label htmlFor="current-password" className="form-label">
                Mot de passe
              </label>
            </div>
            {errors.password && <div className="invalid-feedback d-block">{errors.password.message}</div>}
          </div>

          <FormButtonLoading isPending={sudoModeMutation.isPending} label="Accéder" className="btn btn-outline-dark float-end" />
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default SudoModeModal;
