import { useForm } from "react-hook-form";
import { Checkbox, CheckboxArray, FormButtonLoading } from "../components";
import { api, ApiError } from "../hooks";
import { useMutation } from "@tanstack/react-query";
import { DefaultErrorsType, MutationData, RitualParametersFormValue } from "../types";
import { Alert } from "react-bootstrap";
import { useAuth } from "../contexts/auth";
import { Link } from "react-router-dom";
import Routes from "../routes";
import { SchoolZoneEnum } from "../utils";

const defaultErrors: Omit<DefaultErrorsType<RitualParametersFormValue>, "schoolZone"> = {
  classNivel: {
    validate: (value) => value.length <= 3 || "Vous ne pouvez pas choisir plus de 3 niveaux.",
  },
};

type RituelParametersFormProps = {
  setParameters?: (param: RitualParametersFormValue) => void;
};

function RituelParametersForm({ setParameters }: Readonly<RituelParametersFormProps>) {
  const { user, setRituelParameters } = useAuth();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isDirty },
  } = useForm<RitualParametersFormValue>({
    defaultValues: async () => await defaultValues,
  });

  const defaultValues = new Promise<RitualParametersFormValue>((resolve) => {
    if (user) {
      resolve({
        schoolZone: user.schoolZone,
        classNivel: user.classNivel,
      });
    } else {
      resolve({
        schoolZone: null,
        classNivel: [],
      });
    }
  });

  const rituelParameters = useMutation<MutationData & { parameters: RitualParametersFormValue }, ApiError<RitualParametersFormValue>, RitualParametersFormValue>({
    mutationFn: (data) => {
      return api.profil.updateRituelParameters(data);
    },
    onSuccess: (data) => {
      setRituelParameters(data.parameters.schoolZone, data.parameters.classNivel);
      reset({ schoolZone: data.parameters.schoolZone, classNivel: data.parameters.classNivel });
    },
    onError: (data) => {
      data.formErrors.forEach(({ name, message }) => {
        setError(name, { message: message });
      });
    },
  });

  const onSubmit = (data: RitualParametersFormValue) => {
    if (user) {
      rituelParameters.mutate(data);
    } else if (setParameters) {
      setParameters(data);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {rituelParameters.isError && rituelParameters.error.formErrors.length === 0 && <Alert variant={rituelParameters.error.variant}>{rituelParameters.error.message}</Alert>}
      {rituelParameters.isSuccess && <Alert variant="success">{rituelParameters.data.message}</Alert>}

      {errors.root && <Alert variant="danger">{errors.root.message}</Alert>}

      {/* <div className="text-center"> */}
      <div className="mb-3">
        <div className="me-3 mb-2" style={{ flexGrow: 3 }}>
          Ma zone de vacances scolaires&nbsp;:
        </div>
        <div className="d-flex flex-wrap" style={{ flexGrow: 0 }}>
          <Checkbox label="Zone A" name="schoolZone" value={SchoolZoneEnum.ZoneA} register={register} error={errors.schoolZone} />
          <Checkbox label="Zone B" name="schoolZone" value={SchoolZoneEnum.ZoneB} register={register} error={errors.schoolZone} />
          <Checkbox label="Zone C" name="schoolZone" value={SchoolZoneEnum.ZoneC} register={register} error={errors.schoolZone} />
          <Checkbox label="Guadeloupe" name="schoolZone" value={SchoolZoneEnum.Guadeloupe} register={register} error={errors.schoolZone} />
          <Checkbox label="Guyane" name="schoolZone" value={SchoolZoneEnum.Guyane} register={register} error={errors.schoolZone} />
          <Checkbox label="Martinique" name="schoolZone" value={SchoolZoneEnum.Martinique} register={register} error={errors.schoolZone} />
          <Checkbox label="Mayotte" name="schoolZone" value={SchoolZoneEnum.Mayotte} register={register} error={errors.schoolZone} />
          <Checkbox label="Réunion" name="schoolZone" value={SchoolZoneEnum.Réunion} register={register} error={errors.schoolZone} />
          <Checkbox label="Corse" name="schoolZone" value={SchoolZoneEnum.Corse} register={register} error={errors.schoolZone} />
          <Checkbox label="Saint Pierre et Miquelon" name="schoolZone" value={SchoolZoneEnum.SaintPierreEtMiquelon} register={register} error={errors.schoolZone} />
          <Checkbox label="Polynésie" name="schoolZone" value={SchoolZoneEnum.Polynésie} register={register} error={errors.schoolZone} />
        </div>
        {errors.schoolZone && <div className="invalid-feedback d-block">{errors.schoolZone.message}</div>}
      </div>
      <div className="mb-3">
        <div className="d-flex flex-lg-row flex-column">
          <div className="me-3 mb-2">Mon niveau ou mes niveaux de classe (3 maximum)&nbsp;:</div>
          <div className="d-flex">
            <CheckboxArray name="classNivel" register={register} value="cp" label="CP" className="me-2" error={errors.classNivel} defaultErrors={defaultErrors.classNivel} />
            <CheckboxArray name="classNivel" register={register} value="ce1" label="CE1" className="ms-1 me-2" error={errors.classNivel} defaultErrors={defaultErrors.classNivel} />
            <CheckboxArray name="classNivel" register={register} value="ce2" label="CE2" className="ms-1 me-2" error={errors.classNivel} defaultErrors={defaultErrors.classNivel} />
            <CheckboxArray name="classNivel" register={register} value="cm1" label="CM1" className="ms-1 me-2" error={errors.classNivel} defaultErrors={defaultErrors.classNivel} />
            <CheckboxArray name="classNivel" register={register} value="cm2" label="CM2" className="ms-1 me-2" error={errors.classNivel} defaultErrors={defaultErrors.classNivel} />
          </div>
        </div>
        {errors.classNivel && <div className="invalid-feedback d-block">{errors.classNivel.message}</div>}
      </div>

      <FormButtonLoading disabled={!isDirty} type="submit" isPending={rituelParameters.isPending} label={user ? "Sauvegarder" : "Accéder"} className="btn btn-primary" />
    </form>
  );
}

export default RituelParametersForm;
