import axios from "axios";
import { getPostPutAxiosInstance, handleError } from "./utils";

class HelpRequestAPI {
  async treat(id: number) {
    try {
      const response = await getPostPutAxiosInstance().put("/help-request/treat/" + id);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async add(data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/help-request/add", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default HelpRequestAPI;
